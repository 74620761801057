/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Media from 'react-media';
import mbpBrandClient from 'mbp-api-brand';
import sdkFeatureFlags from 'feature-flags-sdk';
import mbpLogger from 'mbp-logger';
import {
    func, node, object, shape, string,
} from 'prop-types';
import mbpUtil from 'mbp-api-util';

import useSSRMediaQuery from '../../../helpers/hooks/useSSRMediaQuery';
import PDPSkeleton from '../GraphqlSkeletonComponents/PDPSkeleton';
import MobilePDPSkeleton from '../GraphqlSkeletonComponents/MobileSkeleton/MobilePDPSkeleton';
import { getBrand, getUserRole, getUserType } from '../../../../state/ducks/Member/ducks/Common/Common-Selectors';
import { getFeatureFlags, getPresentationFamily } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getBrandIdByHostName } from '../../../helpers/brandIdentification/brandIdentification';
import { getSSRConfig, getSSRDeviceType } from '../../../../state/ducks/App/App-Selectors';
import { getBrowser, getOperatingSystem, getUserKey } from '../../../../state/ducks/App/ducks/Config/Config-Helpers';
import { getProfileInfo } from '../../../../state/ducks/App/ducks/Common/Common-Selectors';

/*
    * **** ONLY WRAPPED AROUND PAGE CONTAINER COMPONENTS **** *

    * Cross Merch container currently switches following properties in any cross merch component
        * presentation_family
        * brand
    * These properties are accessible to child components in props and overrides the ones being passed in props to child component
*/

const CrossMerch = React.createContext({ featureFlags: {}, presentation_family: 'flower' });

const CrossMerchContainer = ({
    children, crossMerchBrand, defaultBrand, featureFlags, refetchQuery, defaultPresentationFamily,
    deviceType, profile, userStatus, userType, userKey, productBrandId,
}) => {
    const componentBrand = crossMerchBrand;
    const device = useSSRMediaQuery();
    const [flags, setFlags] = useState(null);
    /**
     * We need to call Flags again for cross-brand products only
     * This won't fire if we are opening same brand product even the flag is enabled
     */
    const callFeatureFlags = () => {
        const operatingSystem = getOperatingSystem();
        const browser = getBrowser();
        let sessionUserKey = userKey?.userKey;
        const isSSR = (typeof window === 'undefined');
        if (!isSSR) {
            sessionUserKey = getUserKey(sessionUserKey);
        }

        const payload = {
            userKey: sessionUserKey || 'anonymous',
            userType: userType || 'G',
            userStatus,
            email: profile.email || '',
            firstName: profile.firstName,
            lastName: profile.lastName,
            browser,
            operatingSystem,
            brand: crossMerchBrand,
            version: 'rel',
            device: deviceType,
        };
        const flagEnv = mbpUtil.getEnv('APP_FLAG_ENV') || 'mbp-ui';

        sdkFeatureFlags.getFeatureFlags({}, flagEnv, payload).then((res) => {
            setFlags(res.data);
        }).catch(() => {
            // just to prevent errors
            setFlags(featureFlags); // if fail it will use current brands feature
        });
    };

    const ComponentProps = {
        brand: defaultBrand,
        presentation_family: defaultPresentationFamily,
        featureFlags,
    };

    const isCrossMerchFeatureEnabled = featureFlags['is-cross-merch-container-enabled']
        && componentBrand?.length
        && componentBrand !== defaultBrand['domain-name'];

    useEffect(() => {
        if (isCrossMerchFeatureEnabled && refetchQuery) {
            // refetchQuery is a function in which we can pass function to fetch or refetch the data from graphql
            // Example: To refetch the product data from product brand stack in CMS to get additional information from brand stack
            refetchQuery();

            // getting flags based on product brand
            callFeatureFlags(); // only fire if flag is enabled and
        }
        const yotpoCrossBrandFlag = featureFlags?.['which-product-yotpo-cross-brand-key'] || {};
        if (isCrossMerchFeatureEnabled && yotpoCrossBrandFlag && typeof yotpoCrossBrandFlag === 'object' && Object.keys(yotpoCrossBrandFlag)?.length > 0) {
            const scriptUrl = `https://w2.yotpo.com/${yotpoCrossBrandFlag?.[productBrandId]}/widget.js`;
            if (typeof document !== 'undefined' && document.querySelectorAll(`script[src="${scriptUrl}"]`).length === 0) {
                const script = document.createElement('script');
                script.src = scriptUrl;
                script.onload = () => {
                    setTimeout(() => {
                        if (typeof window !== 'undefined' && typeof window.yotpo !== 'undefined' && typeof window.yotpo.refreshWidgets === 'function') {
                            try {
                                if (window.yotpo.initialized) {
                                    window.yotpo.refreshWidgets();
                                }
                            } catch (exception) {
                                mbpLogger.logInfo({ appName: process.env.npm_package_name, message: 'Cross Merch:: Unable to refresh the yotpo widget' });
                            }
                        }
                    }, 2000);
                };
                document.head.appendChild(script);
            }
        }

        return () => {
            if (isCrossMerchFeatureEnabled && yotpoCrossBrandFlag && typeof yotpoCrossBrandFlag === 'object' && Object.keys(yotpoCrossBrandFlag)?.length > 0) {
                const scriptUrl = `https://w2.yotpo.com/${yotpoCrossBrandFlag?.[productBrandId]}/widget.js`;
                if (typeof document !== 'undefined' && document.querySelectorAll(`script[src="${scriptUrl}"]`).length > 0) {
                    try {
                        document.querySelector(`script[src="${scriptUrl}"]`).remove();
                    } catch (e) {
                        mbpLogger.logDebug({
                            productBrandId,
                            function: 'CrossMerch Yotpo brand specific',
                            module: 'mbp-pwa-ui',
                            message: e,
                        });
                    }
                }
            }
        };
    }, [isCrossMerchFeatureEnabled]);

    if (isCrossMerchFeatureEnabled && !flags) {
        return React.cloneElement((
            <Media
                query="(min-width: 599px)"
                defaultMatches={device === 'desktop'}
            >
                {(matches) => (matches
                    ? <PDPSkeleton />
                    : <MobilePDPSkeleton />

                )}
            </Media>
        ), ComponentProps);
    }

    if (isCrossMerchFeatureEnabled) {
        const brandId =  getBrandIdByHostName(crossMerchBrand);
        mbpBrandClient.getBrandById({}, brandId).then((res) => {
            ComponentProps.brand = res;
        });

        ComponentProps.presentation_family = featureFlags?.['which-cross-merch-brands-list']?.[productBrandId] || defaultPresentationFamily;
        ComponentProps.featureFlags = flags || featureFlags;

        // we are using the default feature according to a brand, not CrossMerch according
        ComponentProps.featureFlags['is-mini-cart-enabled'] = featureFlags['is-mini-cart-enabled'];
        ComponentProps.featureFlags['is-mini-cart-v2-enabled'] = featureFlags['is-mini-cart-v2-enabled'];
    }

    return (
        <CrossMerch.Provider value={{ featureFlags: ComponentProps.featureFlags, presentation_family: ComponentProps.presentation_family }}>
            {React.cloneElement(children, ComponentProps)}
        </CrossMerch.Provider>
    );
};

const mapStateToProps = (state) => ({
    defaultBrand: getBrand(state),
    featureFlags: getFeatureFlags(state),
    defaultPresentationFamily: getPresentationFamily(state),
    deviceType: getSSRDeviceType(state),
    profile: getProfileInfo(state),
    userStatus: getUserRole(state),
    userType: getUserType(state),
    userKey: getSSRConfig(state),

});

CrossMerchContainer.propTypes = {
    children: node.isRequired,
    crossMerchBrand: string.isRequired,
    defaultBrand: string.isRequired,
    featureFlags: object.isRequired,
    refetchQuery: func.isRequired,
    defaultPresentationFamily: string.isRequired,
    deviceType: string.isRequired,
    profile: shape({
        email: string,
        firstName: string,
        lastName: string,
    }).isRequired,
    userStatus: string.isRequired,
    userType: string.isRequired,
    userKey: shape({
        userKey: string,
    }).isRequired,
    productBrandId: string,
};

CrossMerchContainer.defaultProps = {
    productBrandId: '',
};

export default connect(mapStateToProps)(CrossMerchContainer);
export { CrossMerch };
