/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SkeletonStyles from '../SkeletonStyles';

const styles = () => ({
    // Import Skeleton layout
    ...SkeletonStyles,

    // Page Elements
    root: {},
    pageWrapper: {
        width: '100%',
        margin: '15px auto',
        display: 'flex',
        maxWidth: 1376,
    },
    grid: {
        width: '100%',
        maxWidth: 980,
    },
    productInfo: {
        '&--blockBody': {
            width: '100%',
            height: 400,
        },
        '&--passportTitle': {
            width: '100%',
            height: 30,
            maxWidth: 200,
            margin: 'auto',
            marginTop: 10,
            marginBottom: 10,
        },
        '&--blockPickItem': {
            width: '100%',
            height: 280,
        },
        '&--passport': {
            width: '100%',
            height: 60,
            margin: '10px 0',
        },
        '&--delivery': {
            width: '100%',
            height: 190,
            margin: '10px 0',
        },
        '&--description': {
            width: '100%',
            height: 300,
            margin: '10px 0',
        },
    },
});

const MobilePDPSkeleton = ({ classes }) => (
    <div className={classes.root}>
        <div className={classes.pageWrapper}>
            <div className={classes.grid}>
                <div className={`${classes.productInfo}--blockBody ${classes.block}`} />
                <div className={`${classes.productInfo}--passportTitle ${classes.block}`} />
                <div className={`${classes.productInfo}--blockHeader ${classes.block}`} />
                <div className={`${classes.productInfo}--blockPickItem ${classes.block}`} />
                <div className={`${classes.productInfo}--passport ${classes.block}`} />
                <div className={`${classes.productInfo}--delivery ${classes.block}`} />
                <div className={`${classes.productInfo}--description ${classes.block}`} />
            </div>
        </div>
    </div>
);

MobilePDPSkeleton.propTypes = {
    classes: object.isRequired,
};

export default withStyles(styles)(MobilePDPSkeleton);
