/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SkeletonStyles from './SkeletonStyles';

const styles = (theme) => ({
    // Import Skeleton layout
    ...SkeletonStyles,

    // Page Elements
    root: {},
    pageWrapper: {
        width: '100%',
        margin: '15px auto',
        display: 'flex',
        maxWidth: 1376,
        [theme?.breakpoints?.down('md')]: {
            padding: '0 20px 0',
        },
    },
    gridLeft: {
        width: '100%',
        maxWidth: 665,
        marginRight: 24,
        [theme?.breakpoints?.down('md')]: {
            flex: '1 1 auto',
            display: 'flex',
            maxWidth: '40%',
            marginRight: 20,
            flexDirection: 'column',
        },
    },
    gridRight: {
        width: '100%',
        maxWidth: 980,
        minWidth: 392,
        [theme?.breakpoints?.down('md')]: {
            width: 'calc(60% - 20px)',
            display: 'flex',
            flexDirection: 'column',
        },
    },
    productImageBlock: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        [theme?.breakpoints?.down('md')]: {
            flexDirection: 'column',
            justifyContent: 'flex-start',
        },
    },
    productImageWrapper: {
        flex: '1 1 auto',
        order: 2,
        height: '100%',
        position: 'relative',
        maxWidth: 600,
        [theme?.breakpoints?.down('md')]: {
            order: 1,
        },
    },
    altImageContainer: {
        display: 'inline-block',
        flexWrap: 'wrap',
        maxWidth: 110,
        order: 1,
        width: '100%',
        marginRight: 10,
        [theme?.breakpoints?.down('md')]: {
            order: 2,
            margin: '20px 0 0 0',
            display: 'flex',
            maxWidth: '100%',
        },
    },
    altImage: {
        marginBottom: 10,
        width: '100%',
        height: 113,
        [theme?.breakpoints?.down('md')]: {
            marginRight: 10,
            width: 110,
        },
    },
    heroImage: {
        marginBottom: 10,
        width: '100%',
        height: 650,
        [theme?.breakpoints?.down('md')]: {
            height: 430,
        },
    },
    smartGift: {
        width: '100%',
        height: 113,
    },
    productInfo: {
        '&--blockHeader': {
            margin: '10px 0',
            width: '100%',
            height: 32,
        },
        '&--blockBody': {
            width: '100%',
            height: 202,
        },
        '&--passport': {
            margin: '20px 0',
            width: '100%',
            height: 77,
        },
    },
});

const PDPSkeleton = ({ classes }) => (
    <div className={classes.root}>
        <div className={classes.pageWrapper}>
            <div className={classes.gridLeft}>
                <div className={classes.productImageBlock}>
                    <div className={classes.productImageWrapper}>
                        <div className={`${classes.heroImage} ${classes.block}`} />
                        <div className={`${classes.smartGift} ${classes.block}`} />
                    </div>
                    <div className={classes.altImageContainer}>
                        <div className={`${classes.altImage} ${classes.block}`} />
                        <div className={`${classes.altImage} ${classes.block}`} />
                    </div>
                </div>
            </div>
            <div className={classes.gridRight}>
                <div className={`${classes.textLG}`} />
                <div className={`${classes.productInfo}--blockHeader ${classes.block}`} />
                <div className={`${classes.productInfo}--blockBody ${classes.block}`} />
                <div className={`${classes.productInfo}--passport ${classes.block}`} />
                <div className={`${classes.productInfo}--blockHeader ${classes.block}`} />
                <div className={`${classes.productInfo}--blockBody ${classes.block}`} />
            </div>
        </div>
    </div>
);

PDPSkeleton.propTypes = {
    classes: object.isRequired,
};

export default withStyles(styles)(PDPSkeleton);
